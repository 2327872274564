import { template as template_85747ab1c3c149079949c29185b4a420 } from "@ember/template-compiler";
const WelcomeHeader = template_85747ab1c3c149079949c29185b4a420(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
