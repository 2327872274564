import { template as template_200fcbf145c04353b8587786dfeca81c } from "@ember/template-compiler";
const FKControlMenuContainer = template_200fcbf145c04353b8587786dfeca81c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
