import { template as template_1f521b64817f4e87b75cba7faa33cf01 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1f521b64817f4e87b75cba7faa33cf01(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
