import { template as template_ae0a8da093ec42ffba03482c104f2166 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ae0a8da093ec42ffba03482c104f2166(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
